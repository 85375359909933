<template>

<!-- <v-navigation-drawer
      v-model="drawer"
      absolute
      class="left-menu-top"
      bottom
      temporary
      solo
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title>Foo</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Bar</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Fizz</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Buzz</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->
    <v-navigation-drawer
      v-model="drawer"
      permanent
      solo
      width="280"
      class="left-menu-top"
      bottom
      fixed
      style="margin-top: 65px"
    >
      <v-list class="side-nav">
        <template v-for="(n, i) in nav">
          <v-list-item v-if="n.to" :key="`${i}-a`" :to="n.to" link>
            <v-list-item-content v-if="n.showToAll">
              <v-list-item-title >{{ n.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="n.subItems && n.showToAll"
            :key="`${i}-b`"
            :prepend-icon="`${n.icon} fa-em`"
            v-model="n.active"
            append-icon="mdi mdi-chevron-down "
          >
            <template v-slot:activator v-if="n.showToAll || setmenu()">
              <v-list-item-content>
                 <!-- <a class="click-links" :href="nav.link">
                <v-list-item-title>{{ n.label }}</v-list-item-title>
                 </a> -->
                 
                <v-list-item-title>{{ n.label }}</v-list-item-title>
                <!-- <v-list-item-title>{{ n.label }}</v-list-item-title> -->
               
              </v-list-item-content>
            </template>
            <v-list-item 
              v-for="(s, y) in n.subItems"
              :key="y"
              :to="s.to"
              link
              class="pl-8"
            >
              <v-list-item-content>
                <v-list-item-title
                @click="linkActive()"
                >
                  {{ s.label }}
                  </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
  
</template> 
<script>
import API_Util from "../services/util/API_Util.js";
export default {
  name: "navigation",
  props: ['menuClicked','ownerType'],
  data: () => ({
    nav:[],
    mainSidebarDrawer: true,
    mini: true,
    drawer:true,
    owner: "",
    windowsize:0,
    marketing: "",
    sessionObj: {},
    otherMenu: "",
    marketMenu: "",
    apiKey: "",
    userId: "",
  }),
  created(){
    this.sessionObj = this.$session.get("user_session");
    this.apiKey = this.sessionObj.session.userApiKey;
    this.userId = this.sessionObj.session.id;
    
    if (this.sessionObj.session.uAccess == undefined) {
      this.getUserAccess();
    }else{
      this.uAccess = this.sessionObj.session.uAccess;
    }
    this.getMenuAccess(this.sessionObj.session.uAccess.MARKETING);
    this.setmenu();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if(window.location.pathname.startsWith("/ownersettings")){
      this.nav[0].active=true;
      if(this.windowsize>1200){
        this.$emit('menuClicked',"");
      }
    }
    if (this.sessionObj.session.uAccess.MARKETING && this.sessionObj.session.id == 2) {
      if(this.sessionObj.session.id!=2){
        this.nav[2].active=false;
        this.$emit('menuClicked',"");
      }
    }
   

  },
  methods: {
    getUserAccess() {
      var url = API_Util.URLS.getUserAccessInfo;
      API_Util.URLS.axios
        .post(url, {},{
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.sessionObj.session.uAccess = response.data;
          this.$session.set("user_session",this.sessionObj);
          if (response.data.MARKETING && this.userId != 2) { 
            this.$router.push("/discount");
            localStorage.setItem('MARK', true);
            location.reload();         
          }
          
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getMenuAccess(market) {
      if (JSON.parse(localStorage.getItem('SUPER'))) {
          if (JSON.parse(localStorage.getItem('ownerType') == 'PM')) {
            this.otherMenu = true;
            this.marketMenu = true;
            this.creditmenu= true;
          } else {
              this.otherMenu = true;
              this.marketMenu = false;
              this.creditmenu= false;
          } 
      }
      else if (market) {
          this.otherMenu = false;
          this.marketMenu = true;
          this.creditmenu= false;
      } else {
          this.otherMenu = true;
          this.marketMenu = false;
          this.creditmenu= false;
      }
    },
    handleResize() {
      this.windowsize=window.innerWidth;
    },
    linkActive(){
      this.nav[0].subItems[0].to = "/ownersettings";
    },
    setmenu(){
      this.nav= [
      {
        to: "",
        label: "Platform Setting",
        showToAll:  this.otherMenu,
        active: false,
        subItems: [
          { to: "/ownersettings", label: "Owner Settings", icon: "mdi-link" },
          { to: "/planDetails", label: "Plan Details", icon: "mdi-link" },
          { to: "/predefined", label: "Pre-defined Groups", icon: "mdi-link" },
          { to: "/platformFunction", label: "Functions", icon: "mdi-link" },
          { to: "/manageorgs", label: "Organisations", icon: "mdi-link" },
        ],
      },
      {
        to: "",
        label: "Content",
        showToAll: this.otherMenu,
        active: false,
        subItems: [
          { to: "/demoapp", label: "Demo Apps", icon: "mdi-car" },
          { to: "/microservices", label: "Microservices", icon: "mdi-car" },
        ],
      },
      // {
      //   to: "",
      //   label: "Deployments",
      //   showToAll: this.otherMenu,
      //   active: false,
      //   subItems: [
      //     // { to: "/demoapp", label: "Demo Apps", icon: "mdi-car" },
      //     { to: "/registermicroservice", label: "Register Microservice", icon: "mdi-car" },
      //   ],
      // },
      {
        to: "",
        label: "Marketing",
        showToAll: this.marketMenu,
        active: false,
        subItems: [
          { to: "/discount", label: "Discounts", icon: "mdi-star" },
          { to: "/promocode", label: "Promo Codes", icon: "mdi-star" },
          { to: "/referralcode", label: "Referral Codes", icon: "mdi-star" },
        ],
      },
      {
        to: "",
        label: "Localisation",
        showToAll: this.otherMenu,
        active: false,
        subItems: [
          { to: "/language", label: "Language", icon: "mdi-filter" },
          { to: "/label", label: "Labels", icon: "mdi-filter" },
          { to: "/error", label: "Errors", icon: "mdi-filter" },
          { to: "/codelist", label: "Codes", icon: "mdi-filter" },
          
        ],
      },
      {
        to: "",
        label: "Credit Management",
        showToAll: this.creditmenu,
        active: false,
        subItems: [
        { to: "/creditmanagement", label: "Credit Management", icon: "mdi-link" },
        ],
      },
    ]
    }
  },
  watch: {
    menuClicked: function() {
      this.drawer = !this.drawer;
    },
    ownerType(){
      this.setmenu();
    }
  }
};
</script>
<style lang="scss" scoped>
.close-menu{
  left: -280px !important;
}
.left-menu-top {
  height: calc(100% - 64px) !important;
  overflow: inherit;
  .v-list{
        padding:0 !important;

  }
}
::v-deep .v-list-item{
  min-height: 60px !important;
}
::v-deep .v-list-group__items{
  .v-list-item.v-list-item--link{
    min-height: 40px !important;
    padding-left:30px !important;
  }
}
// .side-nav{.v-list-group__items{
//   .v-list-item.v-list-item--link.theme--light{
//   background-color:#f8f8f8 !important;
//   }
// }
// }
// .v-list-group__items{
// .v-list-item.v-list-item--link{
//       background-color: red !important;
// }
// }
.activeClass{
   background-color: red !important;
}
@media only screen and (max-width: 768x) {
 
  .close-menu{
  padding-left: 0px !important;
}
}
@media only screen and (min-width: 350px) and (max-width: 767px) {
  .close-menu{
  padding-left: 0px !important;
  left: 0;
}
}
</style>
